<template>
  <div class="container">
    <div class="containerWrap">
      <div class="topLogo">
        <div class="topLogoMain">
          <div class="topLogoMainLeft">
            <router-link to="/">
              <!-- <img src="../assets/image/zgloge.png"  class="Nlogo"> -->
              <div class="Nlogo"></div>
            </router-link>
          </div>
          <div class="topLogoMainRight">
            <div class="main_search">
              <div class="addsc">
                <div
                  class="headLang zh trans"
                  :class="{ active: $i18n.locale == 'zh-CN' }"
                  @click="changeLang('zh-CN')"
                >
                  中文简体
                </div>
                <div
                  class="headLang en trans"
                  :class="{ active: $i18n.locale == 'en-US' }"
                  @click="changeLang('en-US')"
                >
                  English
                </div>
                <div class="weixin" @mouseenter="showwx" @mouseleave="showwxno">
                  <img src="../assets/image/weixin2.png" alt="" />
                </div>
                <div id="hover_wx" class="hover_wx" v-show="isshowwx">
                  <img src="../assets/image/gzh2.jpg" alt="" class="weixi_img" />
                </div>
              </div>
            </div>
            <div class="header_search">
              <div style="float: right;">
                <input
                  type="text"
                  v-model="searchValue"
                  name="KeyWord"
                  required="required"
                  :placeholder="$t('m.searchTxt')"
                  class="form-control"
                  style="float:left;"
                />
                <el-button type="submit" class="btn " @click="handleSearch" style="float:left;"
                  ><i class="iconfont">&#xe782; </i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="topMenu" :class="{ on: active == 'party' }">
        <div class="topMenuMain">
          <div class="nav_box">
            <!-- 中文菜单 -->
            <ul v-if="$i18n.locale == 'zh-CN'" class="menuUL">
              <li class="menuLi">
                <div class="nav_grop">
                  <h2>
                    <router-link to="/"
                      ><span
                        ><i class="iconfont" style="color: #fff;margin-right: 4px; font-size:18px;">&#xe727;</i></span
                      >首页</router-link
                    >
                  </h2>
                </div>
              </li>
              <li v-for="(item, index) in navList" :key="index" class="menuLi">
                <div
                  class="nav_grop"
                  @mouseenter="navShow(item.channelId, item.name, item.child[0].entTChannelUuid)"
                  @mouseleave="navClose()"
                >
                  <h2>
                    <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
                      {{ item.name }}
                    </a>

                    <!-- 有二级，非党建 且 非企业文化 -->
                    <a
                      v-if="
                        item.child.length &&
                          (!item.child[0].url || item.child[0].url == '') &&
                          item.entTChannelUuid != '0181' &&
                          item.entTChannelUuid != '0183'
                      "
                      :href="
                        item.child[0].catalog == 1
                          ? `/listPage/${item.child[0].entTChannelUuid}`
                          : item.child[0].catalog == 2
                          ? `/list/${item.child[0].entTChannelUuid}`
                          : `/listPic/${item.child[0].catalog}`
                      "
                    >
                      {{ item.name }}
                    </a>
                    <!-- 党建 -->
                    <a v-if="item.entTChannelUuid == '0181'" :href="`/party`">
                      {{ item.name }}
                    </a>
                    <!-- 企业文化 -->
                    <a v-if="item.entTChannelUuid == '0183'" :href="`/listVideo/${item.child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                  </h2>

                  <el-collapse-transition>
                    <!-- 党建动态，业务板块不下拉 -->
                    <!-- <div class="nav_down" v-show="navDown == item.channelId && item.channelId != '846'&& item.channelId != '847'"> -->
                    <div
                      class="nav_down"
                      v-show="navDown == item.channelId && item.channelId != '863' && item.channelId != '864'"
                    >
                      <div class="nav_down_wrap clearfloat" v-show="item.child.length != 0">
                        <p v-for="(item1, index1) in item.child" :key="index1">
                          <a v-if="item1.url && item1.url !== ''" target="_blank" :href="item1.url">
                            {{ item1.name }}
                          </a>
                          <!-- 企业文化 非中甘和我 且 非企业影像-->
                          <a
                            v-if="
                              (!item1.url || item1.url == '') &&
                                item1.entTChannelUuid != '01830005' &&
                                item1.entTChannelUuid != '01830001' &&
                                item1.entTChannelUuid != '01800005'
                            "
                            :href="
                              item1.catalog === 1
                                ? `/listPage/${item1.entTChannelUuid}`
                                : item1.catalog === 2
                                ? `/list/${item1.entTChannelUuid}`
                                : `/listPic/${item1.entTChannelUuid}`
                            "
                          >
                            {{ item1.name }}
                          </a>
                          <!-- 中甘和我 -->
                          <a style="display: none" v-if="item1.entTChannelUuid == '01800005'" :href="`/list/01800005`">
                            {{ item1.name }}
                          </a>
                          <a v-if="item1.entTChannelUuid == '01830005'" :href="`/listPicTwo/01830005`">
                            {{ item1.name }}
                          </a>
                          <!-- 企业影像 -->
                          <a v-if="item1.entTChannelUuid == '01830001'" :href="`/listVideo/01830001`">
                            {{ item1.name }}
                          </a>
                        </p>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </li>
            </ul>

            <!-- 英文菜单 -->
            <ul v-if="$i18n.locale == 'en-US'" class="menuUL">
              <li class="menuLi">
                <div class="nav_grop">
                  <h2>
                    <router-link to="/"
                      ><span
                        ><i class="iconfont" style="color: #fff;margin-right: 4px; font-size:18px;">&#xe727;</i></span
                      >Home</router-link
                    >
                  </h2>
                </div>
              </li>
              <li v-for="(item, index) in navListEn" :key="index" class="menuLi">
                <div class="nav_grop" @mouseenter="navShow(item.channelId)" @mouseleave="navClose()">
                  <h2>
                    <a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
                      {{ item.name }}
                    </a>

                    <!-- 非党建动态 且 非企业文化 且非 enbusiness -->
                    <a
                      v-if="
                        item.child.length &&
                          (!item.child[0].url || item.child[0].url == '') &&
                          item.entTChannelUuid != '0181' &&
                          item.entTChannelUuid != '0186'&&
                          item.entTChannelUuid != '0187'
                      "
                      :href="
                        item.child[0].catalog == 1
                          ? `/listPage/${item.child[0].entTChannelUuid}`
                          : item.child[0].catalog == 2
                          ? `/list/${item.child[0].entTChannelUuid}`
                          : `/listPic/${item.child[0].entTChannelUuid}`
                      "
                    >
                      {{ item.name }}
                    </a>
                    <!-- 党建动态 -->
                    <a v-if="item.entTChannelUuid == '0181'" :href="`/party`">
                      {{ item.name }}
                    </a>
                    <!-- Corporate Culture -->
                    <a v-if="item.entTChannelUuid == '0186'" :href="`/listVideo/${item.child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                    <!-- enbusiness -->
                    <a v-if="item.entTChannelUuid == '0187'" :href="`/enbusiness/${item.child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                  </h2>

                  <!-- <el-collapse-transition>
                      <div class="nav_down" v-show="navDown == item.channelId && item.channelId != '846'&& item.channelId != '847'">
                        <div class="nav_down_wrap clearfloat" v-show="item.child.length != 0">
                          <p v-for="(item1, index1) in item.child" :key="index1">
                            <a 
                              v-if="item1.url && item1.url !== ''" 
                              target="_blank" 
                              :href="item1.url">
                              {{item1.name}}
                            </a>
                            <a 
                              v-if="(!item1.url || item1.url == '') && item1.entTChannelUuid != '01860004' && item1.entTChannelUuid != '01860001' "
                              :href="item1.catalog === 1 ? `/listPage/${item1.entTChannelUuid}` : item1.catalog === 2 ? `/list/${item1.entTChannelUuid}` : `/listPic/${item1.entTChannelUuid}`">
                              {{item1.name}}
                            </a>
                            <a 
                              v-if="item1.entTChannelUuid == '01860004'" 
                              :href="`/listPicTwo/01860004`">
                              {{ item1.name }}
                            </a>
                            <a 
                              v-if="item1.entTChannelUuid == '01860001'" 
                              :href="`/listVideo/01860001`">
                              {{ item1.name }}
                            </a>
                          </p>
                        </div>
                      </div>
                    </el-collapse-transition> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <transition name="el-zoom-in-center" appear>
        <div class="main" style="background-color: #fff">
          <router-view></router-view>
        </div>
      </transition>

      <div id="footer">
        <div class="footer_top">
          <div class="layout flex">
            <div style="width: 90px">{{ $t('m.footerLinkName') }}：</div>
            <ul>
              <li v-for="(item, index) in $t('m.footerLink')" :key="index">
                <el-divider v-if="index !== 0" direction="vertical"></el-divider>
                <a :href="item.url" target="_blank">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_main">
          <div class="img">
            <div class="layout">
              <div class="main">
                <div class="center">
                  <div class="center_left">
                    <ul v-html="$t('m.footerLeft')"></ul>
                  </div>
                </div>
                <div class="right">
                  <div class="img_ma">
                    <img src="../assets/image/gzh2.jpg" alt="" />
                  </div>
                  <!-- <p>{{ $t('m.footerRight') }}</p> -->
                </div>
              </div>
              <div class="mainlongtext">本网所有文字、图片和音视频资料，版权均属本公司所有，本站样式已版权保护，不得随意转载、镜像与仿制，违者必究。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="top" @click="backTop" v-show="showBtn">
      <i class="iconfont2">&#xe6dd; </i>
    </button>
    <!-- 飘窗 -->
    <!-- <bay-window  ref="bayWindow"></bay-window> -->
  </div>
</template>
<script>
import { getNavChannel } from '@/api/common'
// import bayWindow from '@/components/bayWindow.vue'
export default {
  name: '',
  components: {
    // bayWindow
  },
  data() {
    return {
      lang: 'zh-CN',
      searchValue: '',
      active: 0,
      navList: [],
      navDown: 0,
      isshowwx: false,
      navListEn: [],
      showBtn: false
    }
  },
  mounted() {
    var _this = this
    this.getPath()
    // this.getNavChannel()
    // this.getNavChannelEn()
    _this.active = _this.$route.name
    // _this.$i18n.locale = localStorage.getItem('lang') || 'zh-CN'
    // _this.$i18n.locale = _this.getCookie('lang') || 'zh-CN'
    // console.log(14,_this.$route.name)
    // window.addEventListener('beforeunload', e => _this.beforeunloadHandler(e))

    _this.$i18n.locale = localStorage.getItem('lang') || 'zh-CN'
    // localStorage.setItem('lang', 'zh-CN')

    let lang = localStorage.getItem('lang')
    if (lang == 'en-US') {
      this.getNavChannelEn()
    } else {
      this.getNavChannel()
    }

    window.addEventListener('scroll', this.showbtn, true)
  },
  created() {},
  watch: {
    $route: 'getPath'
  },
  methods: {
    // toTop() {
    //   document.documentElement.scrollTop = 0;
    // },
    // 回到顶部
    showbtn() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 1000) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
    },
    backTop() {
      var timer = setInterval(function() {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 7)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 80)
    },

    // setCookie (name,value,perpetual) {
    //   let exdate = new Date()
    //   exdate.setDate(exdate.getDate() + perpetual)  //exdate.setDate(exdate.getDate() + 365)
    //   document.cookie = name + '=' + value + ';expires=' + exdate.toGMTString()
    // },
    // getCookie(name){
    //   if (document.cookie.length > 0) {
    //     var start = document.cookie.indexOf(name + '=')
    //     if (start !== -1) {
    //       start = start + name.length + 1
    //       let end = document.cookie.indexOf(';', start)
    //       if (end === -1) end = document.cookie.length
    //       return unescape(document.cookie.substring(start, end))
    //     }
    //   }
    //   return ''
    // },
    // delCookie (name) {
    //   var _this = this
    //   var exp = new Date();
    //   exp.setTime(exp.getTime() - 1);
    //   var cval = _this.getCookie(name);
    //   if (cval && cval != null) {
    //     document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
    //   }
    // },
    // beforeunloadHandler() {
    //   localStorage.removeItem('lang')
    // },
    changeLang(val) {
      localStorage.setItem('lang', val)
      var _this = this
      _this.$i18n.locale = val
      _this.lang = val
      _this.$router.push({ path: '/' })
      // localStorage.setItem('lang', val)

      if (val == 'en-US') {
        this.getNavChannelEn()
      } else {
        this.getNavChannel()
      }
    },
    showwx() {
      this.isshowwx = true
    },
    showwxno() {
      this.isshowwx = false
    },
    // 获取导航
    getNavChannel() {
      var _this = this
      let data = {
        applicationUuid: _this.webId
      }
      getNavChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navList = data.slice(0, 7)
          // _this.navListEn = data.slice(7, 12)
        }
      })
    },
    // 获取导航-英文
    getNavChannelEn() {
      var _this = this
      let data = {
        applicationUuid: _this.webIdEn
        // language: 37
      }
      getNavChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navListEn = data.slice(0, 5)
        }
      })
    },
    handleSearch() {
      var _this = this
      if (!_this.searchValue || _this.searchValue == '') {
        this.$message({
          message: '请输入关键词',
          type: 'warning'
        })
      } else {
        window.location.href = `/search/${_this.searchValue}`
      }
    },
    // 二级导航展开
    // navShow(d, n, c) {
    navShow(d) {
      let _this = this
      _this.navDown = d
      // console.log('导航channelId', d)
      // console.log('导航名称', n)
      // if (c) {
      //   console.log('子导航', c)
      // }
    },
    // 二级导航收起
    navClose() {
      let _this = this
      _this.navDown = 0
    },
    haddleNav(index, params) {
      var _this = this

      _this.active = index
      _this.$router.push(params.url)
    },
    getPath() {
      var _this = this
      _this.active = _this.$route.meta.index
    }
  }
}
</script>

<style lang="scss">
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_z6migdv5msn.woff2?t=1672047022552') format('woff2'),
    url('//at.alicdn.com/t/c/font_3165120_z6migdv5msn.woff?t=1672047022552') format('woff'),
    url('//at.alicdn.com/t/c/font_3165120_z6migdv5msn.ttf?t=1672047022552') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.iconfont2 {
  font-family: 'iconfont' !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}
.topLogo {
  // overflow: hidden;
  height: 150px;
  min-width: 1200px;
  line-height: 150px;
}
.topLogoMain {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.topLogoMainLeft {
  float: left;
  height: 150px;
  line-height: 150px;
  color: #fff;
}
.topLogoMainLeft {
  float: left !important;
}
.Nlogo {
  margin-top: 14px;
  float: left;
  width: 600px;
  height: 123px;
  background: url(../assets/image/logosg2.png) no-repeat 100% 100%;
  background-size: 100% 100%;
  text-align: center;
}

.topLogoMainRight {
  float: right !important;
  height: 150px;
}
.main_search {
  height: 50px;
  float: right;
  position: relative;
}
.addsc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  // z-index: 1000;
  padding: 0 10px;
}
.headLang {
  height: 26px;
  width: 86px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  margin-top: 4px;
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  color: #737373;
}
.headLang.active {
  background: #095293;
  border-radius: 4px;
  color: #fff;
}

.weixin img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-top: 18px;
}
.hover_wx {
  width: 130px;
  position: absolute;
  top: 50px;
  right: 20px;
  display: block;
  z-index: 99999;
  box-shadow: 0 0 5px 0 #aaa;
}
.hover_wx img {
  width: 100%;
  z-index: 99999;
}
.header_search {
  // width: 380px;
  padding: 0 10px;
  height: 51px;
  overflow: hidden;
  float: right;
  position: relative;
  margin-top: 62px;
}
.header_search input {
  width: 200px;
  height: 28px;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  line-height: 28px;
  color: #585858;
  border-radius: 0px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  border-radius: 4px 0 0 4px;
}

.form-control {
  display: block;
  padding: 6px 12px;
}
.btn {
  display: inline-block;
  height: 42px;
  width: 90px;
  color: #fff;
  border-radius: 0px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 0 4px 4px 0;
  border: none;
  border: solid 1px #0b599c;
  cursor: pointer;
  background-color: #0b599c;
}
.topMenu {
  height: 50px;
  background-color: #1773bf;
  // border-bottom: 1px #FFFFFF solid;
}
.topMenu.on {
  background-color: #920606;
  // background-color: #ab0003;
}
.topMenu.on .nav_grop {
  border-left: 1px solid #ffff;
}
.topMenu.on .topMenuMain .menuUL li:hover {
  // background-color: #920606 !important;
  background-color: #631111 !important;
}
.topMenu.on .nav_down .nav_down_wrap p a:hover {
  // background-color: #920606 !important;
  background-color: #631111 !important;
}
.topMenu.on .nav_down .nav_down_wrap {
  width: 150px;
  position: absolute;
  top: 50px;
  left: 0;
  border-top: 0;
  text-align: left;
  padding: 0;
  line-height: 36px;
  z-index: 9999;
  background: #920606;
  // background: #ab0003;
}
.topMenu.on .nav_down .nav_down_wrap p a {
  cursor: pointer;
  text-align: center;
  background-color: #ab0003;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  border-top: 1px solid #fff;
}
.topMenuMain {
  width: 100%;
  height: auto;
  // overflow: hidden;
  margin: 0 auto;
  text-align: center;
}
.topMenuMain .menuUL {
  width: 1200px;
  height: 50px;
  margin: 0 auto;
  display: flex;
}
.topMenuMain .menuUL .menuLi {
  position: relative;
  // width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  // float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  flex: 1;
  // z-index: 22;
  // margin-left: 2px;
}
.topMenuMain .menuUL li:hover {
  background-color: rgba(9, 82, 147) !important;
}
.topMenuMain .menuLi a {
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
  color: #ffffff;
}
.topMenuMain .menuLi a {
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
  font-size: 16px;
  color: #ffffff;
  display: block;
  height: 50px;
  line-height: 50px;
}
.nav_grop {
  border-left: 1px solid #095293;
}
.nav_down .nav_down_wrap {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  border-top: 0;
  text-align: left;
  padding: 0;
  line-height: 36px;
  z-index: 9999;
  background: #007fd2;
}
.nav_down .nav_down_wrap p a {
  height: auto;
  cursor: pointer;
  text-align: center;
  background-color: #016ebb;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  border-top: 1px solid #fff;
  box-sizing: border-box;
  line-height: 20px;
  padding: 14px 0;
}
.nav_down .nav_down_wrap p a:hover {
  background-color: rgba(9, 82, 147) !important;
}
.container #footer,
#footer a {
  color: #fff;
}
.container #footer {
  background-color: #095293;
}
.container #footer .footer_top {
  min-height: 40px;
  line-height: 40px;
  min-width: 1200px;
  background-color: #095293;
  font-size: 14px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.container #footer .footer_top .flex {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.container #footer .footer_top ul li {
  float: left;
}
.container #footer .footer_top ul li i {
  margin: 0 10px;
}
.container #footer .footer_top ul li a {
  color: #fff;
}
.container #footer .footer_main {
  min-width: 1200px;
  background-color: #333;
  // background-color: #2086da;
  padding-bottom: 40px;
}
.container #footer .footer_main .main {
  display: flex;
  justify-content: space-between;
  padding-top: 33px;
  box-sizing: border-box;
  width: 900px;
  margin: 0 auto;
}
.container #footer .footer_main .center {
  margin: 0 50px;
  width: calc(100% - 260px);
  position: relative;
}
.container #footer .footer_main .center_left {
  float: left;
  font-size: 14px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #fff;
}
.container #footer .footer_main .center_left li {
  line-height: 30px;
}
.mainlongtext {
  margin: 0 auto;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-EL-GBK;
  font-weight: 400;
  color: #fff;
  width: 800px;
}
.container #footer .footer_main .center_left li:hover,
.mainlongtext:hover {
  transform: translateY(-3px);
}
.container #footer .footer_main .right {
  margin-left: 0;
  margin-right: 10px;
}
.container #footer .footer_main .right .img_ma {
  width: 145px;
  height: 145px;
  background-size: 100% 100%;
  background-position: 50%;
  background-size: 140px 140px;
  background-color: #fff;
}
.container #footer .footer_main .right .img_ma img {
  width: 100%;
  height: 100%;
}
.container #footer .footer_main .right p {
  width: 150px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  white-space: normal;
  font-family: '微软雅黑', 'Microsoft YaHei', sans-serif;
}
.top {
  text-align: center;
  cursor: pointer;
  position: fixed;
  box-sizing: border-box;
  right: 2em;
  bottom: 45px;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.12), 0 3px 3px 0 rgba(223, 221, 221, 0.24);
  background-color: rgb(216, 215, 215);
  z-index: 5;
}
</style>
